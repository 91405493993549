.what-to-expect{
  position: relative;
  background-image: url("/tcm/images/what_to_expect_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 0;
  color: $black;
  @media screen and (max-width: 320px){
    margin-top: 15rem;
  }
  &:before{
    position: absolute;
    content:'';
    display: block;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:0;
    background-color: rgba(255,255,255, 0.9);
  }
  hr{
    border-color: $black;


  }
  .content{
    position: relative;
    z-index: 1;

    .btn{
      box-shadow: none;
      &.read-more{
        margin-top: 2rem;
        max-width: 150px;
        border-color: $grey;
        color: $grey !important;
      }
    }
  }
}
