.header{
  position: absolute;
  background: transparent;
  min-height: 100px;
  width: 100%;
  padding-top: 1.1rem;
  z-index: 99999;
  background: linear-gradient(to bottom,#000,rgba(0,0,0,0));
  .logo-black{
    display: none;
  }

  .logo-hat-black,
  .logo-hat-white{
    display: none;
    width: 110px;
  }

  @media screen and (max-width: 480px){
    .logo-hat-white{
      display: block;
    }
    .logo-white{
      display: none;
    }
  }
  .hamburger{
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9999;
    &.is-active{
      opacity: 1;
    }
    &:hover{
      opacity: 1;
    }
    @media screen and (max-width: 991px) {
      display: block;
    }
    .hamburger-box{
      .hamburger-inner{
        background-color: $white;
        height: 3px;
        &:before,
        &:after{
          background-color: $white;
          height: 3px;
        }
      }
    }
  }

  nav{
    &.desktop{
      @media screen and (max-width: 991px){
        display: none;
      }
    }
    &.mobile{
      position: absolute;
      top: 0;
      bottom: 0;
      height: 0;
      background: $grey;
      right: 0;
      left: 0;
      overflow: hidden;
      transition: all .5s ease-in-out;
      &.active{
        height: 100vh;
        transition: all .5s ease-in-out;
      }
      @media screen and (max-width: 991px){
        display: block;
      }
      ul{
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        li{
          display: block;
          text-align: center;
          margin-bottom: 2rem;
          &.support-btn{
            max-width: 180px;
            margin: 0 auto;
          }
        }
      }
    }
    ul{
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      li{
        display: inline-block;
        position: relative;
        &:after{
          width: 0;
          height: 3px;
          background-color: $orange;
          position: absolute;
          display: block;
          content: '';
          margin: 0 auto;
          right: 0;
          left: 0;
          bottom: -8px;
        }
        &.support-btn{
          border: 1px solid $white;
          padding: .2rem 1rem;
          &.active{
            border: 1px solid $orange;
            &:after{
              width: 0;
            }
          }
        }
        &.active{
          &:after{
            width: 80%;
          }
        }
        a{
          color: $white;
          font-size: 16px;
        }
      }
    }
  }
  &.terms-and-conditions,
  &.privacy,
  &.cookies,
  &.sitemap{
    position: relative;
    background: $grey;
    padding-bottom: 1rem;
    margin-bottom: 5rem;
  }
  &.sticky{
    position: fixed;
    top: 0;
    width: 100%;
    background: $white;
    box-shadow: 10px 2px 8px 0 $grey;
    padding: .3rem 0;
    .logo-black{
      display: block;
    }
    .logo-white{
      display: none;
    }
    @media screen and (max-width: 768px){
      min-height: 80px;

      .logo-hat-white{
        display: none;
      }

      .logo-hat-black{
        display: block;
      }

      .logo-black{
        display: none;
      }


    }
    ul{
      li{
        &.support-btn{
          border: 1px solid $grey;
          padding: .2rem 1rem;
        }
      }
    }
    .hamburger{
      .hamburger-box{
        .hamburger-inner{
          background-color: $black;
          &:before,
          &:after{
            background-color: $black;
          }
        }
      }
      &.is-active{
        .hamburger-box{
          .hamburger-inner{
            background-color: $white;
            &:before,
            &:after{
              background-color: $white;
            }
          }
        }
      }
    }
    nav{
      &.mobile{
        ul{
          li{
            a{
              color: $white;
              &.support-btn{
                color: $white !important;
                border-color: $white !important;
              }
            }
          }
        }
      }
      ul{
        li{
          a{
            color: $grey;
            &.support-btn{
              color: $grey !important;
              border-color: $grey !important;
            }
          }
        }
      }
    }
  }
}







