.adopt-an-object{
    .whats-on{
        @media(max-width:480px){
            .image-with-content-box {
                background-position: center;
            }
        }
    }
    .adoption-items{
        margin-bottom:40px;

        .object{
            margin-bottom:80px;
            .image-wrap{
                height:300px;
                width:100%;
                position:relative;
                overflow: hidden;
                margin-bottom:20px;
                img{
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform:translate(-50%, -50%);
                    max-height:100%;
                    max-width:100%;
                }
                .adopted{
                    font-family: sans-serif;
                    font-weight: 800;
                    text-transform: uppercase;
                    color: $red;
                    font-size: 60px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(10deg);
                    opacity:0.8;
                    &:before,
                    &:after{
                        content:'';
                        border:10px solid $red;
                        padding:1rem;
                    }
                    &:before{
                        border-right:none;
                    }
                    &:after{
                        border-left:none;
                    }
                    @media (min-width:769px) and (max-width:992px){
                        font-size:40px;
                        &:before,
                        &:after{
                            border-width:7px;
                        }
                    }
                    @media (max-width:600px){
                        font-size:40px;
                        &:before,
                        &:after{
                            border-width:7px;
                        }
                    }

                }
            }
            h4{
                font-weight:600;
                margin-bottom:10px;
                border-bottom:2px solid;
                padding-bottom:5px;
            }
            .content{

            }
            .cost{
                display:block;
                text-align: center;
                padding:15px;
                border-top:1px solid;
                border-bottom:1px solid;
                margin:20px 0;
                font-weight:600;
            }
            .limit{

            }
            .patrons{
                .patron{
                    &:after{
                        content:', ';
                    }
                    &:last-child{
                        &:after{
                            content:'';
                        }
                    }
                }
            }
        }
    }
}
