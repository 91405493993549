.learning-hub-resources{
  .dashboard{
    border-bottom: 0;
    .dashboard-content{
      p{
        width: 100%;
      }
    }
  }
  .resources{
    .resource-card{
      .resource-card-header,
      .resource-card-body{
        padding: .4rem .8rem;
      }
      .resource-card-header{
        display: flex;
        justify-content: space-between;
        color: $white;
        align-items: center;
        font-size: 16px;
      }
      .resource-card-body{
        background-color: $white;
        h4{
          font-weight: bold;
          font-size: 18px;
          margin-bottom: .8rem;
        }
        .buttons{
          display: flex;
          justify-content: space-between;
          margin-top: .5rem;
          .btn{
            padding: .5rem .5rem;
            flex-basis: 50%;
            margin-left: 0;
            margin-right: 0;
            &:first-child{
              margin-right: 15px;
            }
          }
        }
        .content{
          font-size: 16px;
          margin-bottom: .8rem;
        }
      }
    }
  }


  .session-modal{
    z-index: 9999999;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    height: 100%;
  }
}