@import url('https://fonts.googleapis.com/css?family=Alegreya:400,700');

@font-face {
  font-family: AlegreyaRegular;
  src: url('/tcm/fonts/Alegreya-Regular.woff');
}

@font-face {
  font-family: AlegreyaBold;
  src: url('/tcm/fonts/Alegreya-Bold.woff');
}

$white: #ffffff;
$black: #000000;

$copper: #C5671C;
$brown: #8A5D1F;
$rasberry: #9F3658;
$orange: #D1630B;
$purple: #794FAC;
$teal: #06858B;
$plum: #642867;
$red: #992E2E;
$green: #1F8B53;
$blue: #2C5F9B;
$grey: #5C5C5C;
$gold: #B4962C;

$transparentCopper: rgba($copper, 0.8);
$transparentBrown: rgba($brown, 0.8);
$transparentRasberry: rgba($rasberry, 0.8);
$transparentOrange: rgba($orange, 0.8);
$transparentPurple: rgba($purple, 0.8);
$transparentTeal: rgba($teal, 0.8);
$transparentPlum: rgba($plum, 0.8);
$transparentRed: rgba($red, 0.8);
$transparentGreen: rgba($green, 0.8);
$transparentBlue: rgba($blue, 0.8);
$transparentGrey: rgba($grey, 0.8);
$transparentGold: rgba($gold, 0.8);

$blackSlate: #313131;

$lightGrey: #D3D3D3;

$lighterGrey: #F2F2F2;

$twitter: #1DA1F2;
$facebook: #3C5A99;
$instagram: #E1306C;

$themeColours:(
        'copper': $copper,
        'brown': $brown,
        'rasberry': $rasberry,
        'orange': $orange,
        'purple': $purple,
        'teal': $teal,
        'plum': $plum,
        'red': $red,
        'green': $green,
        'blue': $blue,
        'grey': $grey,
        'gold': $gold,
        'lightGrey': $lightGrey
);

@each $name, $value in $themeColours{
  .hover-#{$name}{
    background-color: rgba($value, 0.8);
    transition: background-color .5s ease-in-out;
    &:hover{
      background-color: rgba($value, 0.9);
      transition: background-color .5s ease-in-out;
    }
  }
}

@each $name, $value in $themeColours{
  .transparent-#{$name}{
    background-color: rgba($value, 0.8);
    transition: background-color 1s ease-in-out;
  }
}

@each $name, $value in $themeColours{
  aside{
    nav{
      ul{
        li{
          &.active{
            &.accent-#{$name}{
              border-left-color: #{$value} !important;
              a{
                color: $value !important;
              }
            }
          }
        }
      }
    }
  }
}

@each $name, $value in $themeColours{
  .background-#{$name}{
    background-color: $value;
  }
}

@each $name, $value in $themeColours{
  .text-#{$name}{
    color: $value;
  }
}


@each $name, $value in $themeColours{
  aside{
    li{
      &.text-#{$name}{
        a{
          color: $value !important;
        }
      }
    }
  }
}














