.about-us{
  .main-holder{
    border-bottom: 1px solid $lightGrey;
    padding-bottom: 2rem;
  }
  color: $black;
  .page-intro-with-image{
    margin-bottom: 0;
    padding-bottom: 0rem;
  }
  h1{
    color: $black;
  }

  .side-images{
    img{
      margin-bottom: 2.5rem !important;
    }
  }
  .dashboard-content{
    padding-top: 0;
  }
}