body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Alegreya', sans-serif;
  font-size: 19px;
  color: $black;

  &.no-scroll{
    overflow: hidden;
  }
}

h1,
h2,
h3,
.caption{
  font-family: 'Alegreya', sans-serif;
}

h1{
  font-size: 40px;
}

h2{
  font-size: 30px;
}

h3{
  font-size: 20px;
}

.caption{
  font-size: 14px;
}

.btn{
  border: 1px solid;
  box-shadow: none;
  &.btn-gold{
    background-color: $gold;
    color: $white;
    border-color: $gold;
  }
  &.btn-white{
    background-color: $white;
    border-color: $grey;
    color: $grey;
  }
  &.btn-transparent{
    background-color: transparent;
    border-color: $white;
    color: $white;
    &:hover{
      box-shadow: none;
    }
  }
  &:hover{
    box-shadow: 0 2px 4px 0 !important;
  }
}


input[type=date], input[type=datetime-local], input[type=email], input[type=number], input[type=password], input[type=search-md], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], textarea.md-textarea{
  &.form-control{
    border: 1px solid $lightGrey;
  }
  &:focus{
    box-shadow: none !important;
    border: 1px solid $lightGrey !important;
  }
}
hr{
  border: 1px solid $black;
}

.breadcrumbs{
  li{
    a{
      color: $black;
    }
  }
}

select{
  appearance: none;
  background: white;
  padding: .5rem 1rem;
  width: 100%;
  border-radius: 0;
  background-image: url("/tcm/images/select_chevron.png");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 195px center;
}
select{
  &.custom{
    display: none;
  }
}

.nice-select{
  span{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  ul{
    &.list{
     li{
       text-align: center;
       padding: .5rem 0;
       &:hover{
         background-color: #D5D5D5;
       }
       a{
         color: $grey;
         display: block;
       }
     }
    }
  }
}

.text-black{
  color: $black;
}

ul{
  &.pagination{
    justify-content: center;
    align-items: center;
    li{
      &.active{
        span,
        a{
          background-color: $grey !important;
          color: $white !important;
        }
      }
      span,
      a{
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 50% !important;
        font-size: 18px !important;
        color: $grey;
      }
      &.disabled{
        span{
          background-color: transparent !important;
        }
      }
    }
  }
}


.cookie-bar-default,
.cookie-preferences-edit{
  display:none;
}


