.footer-subscribe-section {
    background: $white;
    color: $black;
    padding: 4rem 0;

    .footer-subscribe-section-inner {
        max-width: 550px;
        margin: 0 auto;

        h3 {
            letter-spacing: .3rem;
            text-transform: uppercase;
        }

        form {
            //flex-flow: nowrap;

            .subscribe-input {
                flex-basis: 70%;
                height: 20px;
                margin: 0;
                padding-left: .5rem;
                padding-right: .5rem;
                @media screen and (max-width: 480px) {
                    flex-basis: 50%;
                }

                &::placeholder {
                    color: $lightGrey;
                    text-align: center;
                }
            }

            .subscribe-btn {
                padding: .6rem 1.8rem;
            }

            .gdpr-check{
                .form-check-input{
                    display: inline-block;
                    float: left;
                    margin-top: 5px;
                }
                .form-check-label{
                    font-size: 14px;
                }
            }
        }

        .response{
            &.show {
                margin-top: 10px;
                padding-top:10px;
                border-top: 1px solid rgba($black, 0.3);
                text-align: center;
            }
        }
    }
}

footer {
    &.footer {
        background-color: $grey;
        padding: 4rem 0;
        @media screen and (max-width: 991px) {
            padding-bottom: 1rem;
        }
        color: $white;

        .footer-logo {
            width: 70%;
            @media screen and (max-width: 991px) {
                width: 20%;
                margin-bottom: 2rem;
            }
        }

        .footer-section {
            &:not(:last-child) {
                border-right: 1px solid $white;

                @media screen and (max-width: 991px) {
                    border-right: none;
                }
            }

            .address-info,
            .contact-info {
                font-size: 16px;
                margin-bottom: 0;

                a {
                    color: $white;
                }
            }

            .contact {
                margin-top: 1rem;
            }

            @media screen and (max-width: 991px) {
                text-align: center;

                &:last-child {
                    display: none;
                }
                span {
                    display: block;
                }
            }
        }

        .footer-nav {
            ul {
                padding: 0;
                list-style-type: none;

                li {
                    text-decoration: none;
                    display: inline-block;
                    width: calc(50% - 15px);
                    margin-bottom: .2rem;

                    a {
                        color: $white;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.footer-bottom {
    background-color: $blackSlate;
    padding: .8rem 0;

    nav {
        ul {
            padding: 0;
            text-align: center;
            list-style-type: none;
            margin: 0;

            li {
                display: inline-block;
                margin-right: 3rem;
                font-size: 16px;

                a {
                    color: $lightGrey;
                }
            }
        }
    }
}

