.significance{
  .page-intro-with-image{
    padding-bottom: 1rem;

    .title{
      max-width: 100%;
      text-align: left;
    }
  }

  .dashboard{
    aside{
      background-color: $lighterGrey;
    }
    .dashboard-content{
      background-color: $white;
      padding: 0 2rem 2rem 2rem;
    }
  }
}