$timeline: t1, t2, t3, t4, t5, t6, t7, t8, t9, t10, t11, t12, t13, t14, t15, t16, t17, t18, t19, t20, t21, t22, t23, t24, t25, t26, t27, t28, t29, t30;
$base: 75;
$baseLgLandscape: 60;
$baseTabLandcsape: 55;
$basepx: $base * 1px;
$baseSmLandscape: 60;



@each $item in $timeline{
  $i: index($timeline, $item);
  .line-t#{$i}{
    left: $i * $basepx;
    @media screen and (max-width: 1199px){
      left: $i * $baseLgLandscape+px;
    }
    @media screen and (max-width: 1024px){
      left: $i * $baseTabLandcsape+px;
    }

    @media screen and (max-width: 768px){
      top: $i * $baseSmLandscape+px !important;
      left: unset;
    }
  }
}

.key-events{

  .dashboard-content{
    background-color: $white;
    padding-top: 0;
    @media screen and (max-width: 991px){
      padding-left: 0;
      padding-right: 0;
    }
  }

  .timeline{
    position: relative;
    height: 325px;
    background-color: #f5f5f5;
    padding: 0 3rem;
    overflow: auto;
    margin-bottom: 2rem;
    @media screen and (max-width: 768px){
      height: 650px;
    }

    .main-line{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100vw;
      height: 2px;
      background-color: $lightGrey;
      @media screen and (max-width: 480px){
        top: 10%;
        transform: translateY(0);
      }
      &:after,
      &:before{
        position: absolute;
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background-color: $lightGrey;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }
      &:before{
        right: 0;
        @media screen and (max-width: 768px){
          bottom: 0;
          top: unset;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:after{
        left: 0;
        @media screen and (max-width: 768px){
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .start-date,
      .end-date{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 35px;
        font-size: 14px;
      }
      .start-date{
        left: -40px;
        @media screen and (max-width: 768px){
          top: 0;
        }
      }
      .end-date{
        right: -40px;
        @media screen and (max-width: 768px){
          bottom: 0;
          top: unset;
        }
      }

      .line{
        cursor: pointer;
      }



      @media screen and (max-width: 768px){
        height: 2000px;
        width: 2px !important;
        left: 50%;
      }
    }



    .line{
      position: absolute;
      width: auto;
      @media screen and (max-width: 480px){
        width: 100px;
      }
      p{
        margin-bottom: 0;
        text-align: center;
        font-size: 14px;
        &.date{
          font-weight: bold;
        }
      }
      &.line-top{
        top: -95px;
        @media screen and (max-width: 768px){
          left: -145px;
          top: unset;
        }
        &:after{
          top: 65px;
          @media screen and (max-width: 768px){
            top: 50%;
            transform: translateY(-50%);
            height: 2px;
            width: 32px;
            left: unset;
            right: -48px;
          }
        }
        &:nth-of-type(4n + 3),
        &:nth-of-type(4n + 4)
        {
          top: -131px;
          &:after{
            height: 68px;
            @media screen and (max-width: 768px){
              height: 2px;
              width: 32px;
              right: -74px;
            }
          }


          &:before{
            bottom: -95px;
          }
        }
      }
      &.line-bottom{
        top: 53px;
        @media screen and (max-width: 768px){
          left: 45px;
        }
        &:after{
          bottom: 63px;
          @media screen and (max-width: 768px){
            width: 32px;
            height: 2px;
            top: 50%;
            left: -29px;
          }
        }
        &:before{
          top: -56px;
          @media screen and (max-width: 768px){
            top: 50%;
            left: -48px;
          }
        }
        &:nth-of-type(4n + 3),
        &:nth-of-type(4n + 4)
        {
          top: 90px;
          @media screen and (max-width: 768px){
            left: 45px;
          }
          &:after{
            height: 69px;
            @media screen and (max-width: 768px){
              width: 32px;
              height: 2px;
              top: 50%;
              left: -29px;
            }
          }
          &:before{
            top: -92px;
            @media screen and (max-width: 768px){
              top: 50%;
              left: -48px;
            }
          }
        }
      }
      &:after{
        position: absolute;
        width: 2px;
        height: 32px;
        content: '';
        display: block;
        background-color: $lightGrey;
        left: 50%;
        transform: translateX(-50%);
      }

      &:nth-of-type(4n + 3),
      &:nth-of-type(4n + 4)
      {
        &:after{

        }
      }

      &:before{
        content: '';
        position: absolute;
        background-color: $lightGrey;
        width: 8px;
        height: 8px;
        display: block;
        bottom: -58px;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%);
        @media screen and (max-width: 768px){
          bottom: unset;
          left: unset;
          transform: translateY(-50%);
          top: 50%;
          right: -50px;
        }
      }
    }

    .timeline-model {
      position: absolute;
      height: 0;
      background: $white;
      left: 0;
      right: 0;
      opacity: 0;
      padding: 1rem;
      transition: all .5s ease-in-out;
      top: 50%;
      width: calc(100% - 3rem);
      margin: 0 auto;
      transform: translateY(-50%);
      overflow: auto;

      &.open {
        position: sticky;
        opacity: 1;
        height: calc(100% - 40%);
        z-index: 9999;
        transition: all .5s ease-in-out;
      }

      .date{
        margin-bottom: 0;
        font-weight: bold;
      }
    }

    &.timeline-mobile{
      height: 600px;
      display: none;
      @media screen and (max-width: 768px){
        display: block;
      }
      .main-line{
        width: 2px;
        height: calc(100% - 6rem);
        left: 50%;
        &:before{
          left: 0;
          top: 0;
          right: 50%;
          transform: translateX(-50%);
        }
        &:after{
          left: 0;
          top: unset;
          bottom: 0;
          right: 50%;
          transform: translateX(-50%);
        }

        .start-date,
        .end-date{
          width: 65px;
          left: 50%;
          transform: translateX(-50%);
        }
        .start-date{
          top: -28px;
        }
        .end-date{
          bottom: -28px;
          top: unset;
        }


        .line-top{
          left: -250px;
          @media screen and (max-width: 768px){
            left: -168px;
          }

          &:after{
            top: 50%;
            transform: translateY(-50%);
            width: 127px;
            height: 2px;
            left: 125px;
            @media screen and (max-width: 768px){
              width: 45px;
            }
          }
          &:before{
            transform: translateY(-50%);
            top: 50%;
            left: unset;
            right: -155px;
            @media screen and (max-width: 768px){
              right: -73px;
            }
          }
        }

        .line-bottom{
          right: -250px;
          left: unset;
          @media screen and (max-width: 768px){
            right: -168px;
          }

          &:after{
            width: 124px;
            height: 2px;
            top: 50%;
            left: unset;
            right: 128px;
            transform: translateY(-50%);
            @media screen and (max-width: 768px){
              width: 42px;
            }
          }
          &:before{
            top: 50%;
            right: 247px;
            left: unset;
            transform: translateY(-50%);
            @media screen and (max-width: 768px){
              right: 165px;
            }
          }
        }
      }
    }
  }
}




