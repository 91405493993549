.error-404{
  width: 100%;
  height: 100vh;
  background-image: url('/tcm/images/cromwell_homepage_hero.png');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .error-box{
    padding: 2rem;
    position: absolute;
    color: white;
    top: 50%;
    transform: translateY(-50%);
    right: 20%;
    p{
      width: 45%;
    }
  }
}