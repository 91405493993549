.homepage-hero-slider-wrapper{
  position: relative;
  top: 25%;
  @media screen and (max-width: 991px){
    top: 50%;
  }
  @media screen and (max-width: 480px){
    z-index: 9999;
    width: 100vw;
    left: -15px;
    top: 140px;
  }
  .slide{
    padding: 1rem 2rem;
    .slider-subtitle{
      text-transform: uppercase;
      letter-spacing: 5px;


    }

    .hero-slider-anchor{
      font-weight: bold;
    }
  }
    .homepage-hero-slider{
      color: $white;
      hr{
        border-color: $white;
      }
    }
    .prev-arrow,
    .next-arrow{

    }

  .slider-content{
    .context-info{
      margin-bottom: 2rem;
      p{
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }

  .slider-buttons{
    display: flex;
    justify-content: flex-end;
    i{
      margin-right: .5rem;
    }
  }

  .slider-image{
    @media screen and (max-width: 991px){
      display: none;
    }
  }

  .slider-image,
  .main-content{
    @media screen and (max-width: 480px){
      display: none;
    }
  }
}






