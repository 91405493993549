.info-blocks{
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 768px){
    display: block;
  }
  .block-link{
    color: $white;
  }
  .block{
    padding: 2rem;
    flex-basis: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    color: $white;
    position: relative;
    height: 539px;
    &.block-1{
      background-image: url("/tcm/images/whats_on_block_1_bg.png");
    }
    &.block-2{
      background-image: url("/tcm/images/whats_on_block_2_bg.png");
    }
    &.block-3{
      background-image: url("/tcm/images/whats_on_block_3_bg.png");
     }
    &.block-4{
      background-image: url("/tcm/images/whats_on_block_4_bg.png");
    }
    .block-content{
      text-align: center;
      position: absolute;
      z-index: 1;
      width: calc(100% - 50px);
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);
      hr{
        border-color: $white;
        width: 75%;
      }
      p{
        height: auto;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: all .5s ease-in-out;
        margin-bottom: 2rem;
      }
      .elipses{
        opacity: 1;
        transition: all .5s ease-in-out;
        font-size: 50px;
      }
      .btn{
        max-width: 150px;
      }
    }
    .block-overlay{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &:hover{
      p{
        height: auto;
        max-height: 400px;
        overflow: auto;
        opacity: 1;
      }
      .elipses{
        opacity: 0;
        transition: all.5s ease-in-out;
        font-size: 19px;
      }
    }
  }
}


