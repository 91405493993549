.key-collections{
  .key-collections-wrapper{
    .key-collection{
      border-bottom: 1px solid #D8D8D8;
      color: $black;
      padding-bottom: 3rem;
      padding-top: 2rem;
      &:last-child{
        border-bottom: 0;
      }
      h2{
        margin-bottom: 1rem;
      }
    }
  }

  .modal-key-figures{
    //position: absolute;
    //top: 50%;
    //transform: translateY(-50%);
    z-index: 9999999;
  }

}

.modal-backdrop{
  &.show{
    opacity: 0.8;
    z-index: 999999;
  }
}