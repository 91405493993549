.full-width-image-with-title{
  width: 100%;
  height: 365px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media screen and (max-width: 768px){
    height: 230px;
  }
}