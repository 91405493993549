.discover-more{
  padding: 4rem 0;
  background-color: $lighterGrey;
  .title{
    max-width: 400px;
    margin: 0 auto;
    h3{
      text-align: center;
      letter-spacing: .3rem;
      text-transform: uppercase;
      color: $black;
    }
  }
}