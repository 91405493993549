.home{
  .homepage-hero{
    //background-image: url('/tcm/images/cromwell_homepage_hero.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    position: relative;
    @media screen and (min-width: 992px) and (max-width: 1280px){
      height: 820px;
    }
    //@media screen and (max-width: 480px){
    //  margin-bottom: 5rem;
    //}
    .explore-more{
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.5);
      &:after{
      }
      @media screen and (max-width: 480px){
        display: none;
      }
    }
  }
}

