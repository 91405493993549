.whats-on{
  .image-with-content-box{
    @media screen and (max-width: 480px){
      background-position: -377px center;
    }
  }
  .event-card{
    background-color: $white;
    height: 100%;
    .events-card-image{
      width: 100%;
      height: 162px;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .events-card-body{
      padding: 1.5rem 1rem;
      .btn-transparent{
        border-color: $grey;
        color: $grey !important;
        margin: 0;
      }
      h2{
        margin-bottom: 2rem;
      }
      .btn{
        padding: .3rem 1rem;
        text-transform: none;
        display: block;
        margin: 0 auto;
        font-size: 16px;
      }
      .excerpt{
        margin-bottom: .3rem;
        margin-top: .5rem;
        font-weight: bold;
      }

      .dates,
      .times{
        color: $grey;
      }
    }
  }

  .content-box{
    background-color: $transparentGold;
  }

}






