.learning-hub{
  .info-blocks{
    .block{
      flex-basis: 33.33%;
      flex-grow: 1;
      @media screen and (max-width: 768px){
        flex-basis: 50%;
      }
    }
  }

  .content-box{
    background-color: $transparentRed;
    min-height: 429px;
    height: auto;

    @media screen and (max-width: 1199px){
      top: -45px;
    }

    @media screen and (max-width: 439px){
      top: -145px;
    }
  }
}