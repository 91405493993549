.donate{
  .donate-box{
    width: 100%;
    padding: 2rem;
    position: relative;
    color: $white;
    z-index: 1;
    text-align: center;
    margin-top: 4rem;
    @media screen and (max-width: 768px){
      margin-top: 1rem;
    }
    p{
      font-size: 22px;
    }
    .donate-box-overlay{
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
  }
}