.plan-a-visit{
  h1{
    color: $black;
  }

  .dashboard-content{
    color: $black;
  }

  .contact-info{
    display: flex;
    flex-wrap: wrap;

    address{
      span{
        display: block;
      }
    }

    a{
      color: $black;
    }

    p{
        word-wrap: break-word;
    }
  }

  #map{
    width: 100%;
    height: 260px;
  }


  .info-accordions{
    margin-top: 2rem;
    .accordion{
      &:not(:last-child){
        margin-bottom: 2rem;
      }
      .accordion-header{
        color: $black;
        border-bottom: 1px solid $black;
        position: relative;
        padding-bottom: .5rem;
        cursor: pointer;
        margin-bottom: 1rem;
        i{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
        }
      }
      .accordion-body{
        height: auto;
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s ease-in-out;
        &.open{
          max-height: 1000px;
          transition: max-height .5s ease-in-out;
        }
      }
    }
  }

}