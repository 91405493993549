.image-with-content-box{
  width: 100%;
  height: 548px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 11rem;
  background-position: center center;
  position: relative;
  .content-box{
    padding: 2rem 2rem 3rem 2rem;
    color: $white;
    position: absolute;
    right: 0;
    height: 429px;
    .content-box-subtitle{
      letter-spacing: .2rem;
      margin-bottom: 1.5rem;
      h3{
        font-size: 23px;
        text-transform: uppercase;
      }
      hr{
        border-color: $white;
      }
    }
    @media screen and (max-width: 480px){
      width: 100vw;
      margin-left: -50vw;
      position: relative;
      left: 50%;
    }
  }
  @media screen and (max-width: 768px){
    margin-bottom: 5rem;
  }
  @media screen and (max-width: 480px){
    padding-top: 28rem;
    margin-bottom: 22rem;
  }
}