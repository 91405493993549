.page-intro{
  h1{
    color: $black;
  }
}

.page-intro-with-image{
  text-align: center;
  padding-bottom: 2rem;
  .breadcrumbs{
    text-align: left;
  }
  .featured-image{
    width: 100%;
    height: 548px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media screen and (max-width: 768px){
      height: 230px;
    }
  }
  .title{
    max-width: 630px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 2rem;
    h1{
      color: $black;
    }
  }

  p{
    color: $black;
  }
}

