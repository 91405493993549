.dashboard{
  display: flex;
  border-bottom: 1px solid $lightGrey;
  @media screen and (max-width: 991px){
    display: block;
  }
  aside{
    background-color: #f5f5f5;
    flex-basis: 25%;
    align-self: flex-start;
    @media screen and (max-width: 991px){
      margin-bottom: 1rem;
    }
    nav{
      ul{
        padding: 0;
        margin: 0;
        list-style-type: none;
        li{
          border-left: 8px solid transparent;
          a{
            padding: .8rem 2rem;
            color: $black;
            display: block;
          }
        }
      }
    }
  }
  .dashboard-content{
    padding: 3rem 2rem;
    flex-basis: 75%;
    background-color: #F2F2F2;
    color: $black;
    .filter{
      margin-bottom: 3rem;
      select{
        appearance: none;
        background: white;
        padding: .5rem 1rem;
        width: 100%;
        border-radius: 0;
        background-image: url("/tcm/images/select_chevron.png");
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: 195px center;
      }
    }
  }
}

.default{
  .dashboard{
    border-bottom: 0;
  }
}



