@import url("https://fonts.googleapis.com/css?family=Alegreya:400,700");
@font-face {
  font-family: AlegreyaRegular;
  src: url("/tcm/fonts/Alegreya-Regular.woff");
}

@font-face {
  font-family: AlegreyaBold;
  src: url("/tcm/fonts/Alegreya-Bold.woff");
}

.hover-copper {
  background-color: rgba(197, 103, 28, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-copper:hover {
  background-color: rgba(197, 103, 28, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-brown {
  background-color: rgba(138, 93, 31, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-brown:hover {
  background-color: rgba(138, 93, 31, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-rasberry {
  background-color: rgba(159, 54, 88, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-rasberry:hover {
  background-color: rgba(159, 54, 88, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-orange {
  background-color: rgba(209, 99, 11, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-orange:hover {
  background-color: rgba(209, 99, 11, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-purple {
  background-color: rgba(121, 79, 172, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-purple:hover {
  background-color: rgba(121, 79, 172, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-teal {
  background-color: rgba(6, 133, 139, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-teal:hover {
  background-color: rgba(6, 133, 139, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-plum {
  background-color: rgba(100, 40, 103, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-plum:hover {
  background-color: rgba(100, 40, 103, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-red {
  background-color: rgba(153, 46, 46, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-red:hover {
  background-color: rgba(153, 46, 46, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-green {
  background-color: rgba(31, 139, 83, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-green:hover {
  background-color: rgba(31, 139, 83, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-blue {
  background-color: rgba(44, 95, 155, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-blue:hover {
  background-color: rgba(44, 95, 155, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-grey {
  background-color: rgba(92, 92, 92, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-grey:hover {
  background-color: rgba(92, 92, 92, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-gold {
  background-color: rgba(180, 150, 44, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-gold:hover {
  background-color: rgba(180, 150, 44, 0.9);
  transition: background-color .5s ease-in-out;
}

.hover-lightGrey {
  background-color: rgba(211, 211, 211, 0.8);
  transition: background-color .5s ease-in-out;
}

.hover-lightGrey:hover {
  background-color: rgba(211, 211, 211, 0.9);
  transition: background-color .5s ease-in-out;
}

.transparent-copper {
  background-color: rgba(197, 103, 28, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-brown {
  background-color: rgba(138, 93, 31, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-rasberry {
  background-color: rgba(159, 54, 88, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-orange {
  background-color: rgba(209, 99, 11, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-purple {
  background-color: rgba(121, 79, 172, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-teal {
  background-color: rgba(6, 133, 139, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-plum {
  background-color: rgba(100, 40, 103, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-red {
  background-color: rgba(153, 46, 46, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-green {
  background-color: rgba(31, 139, 83, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-blue {
  background-color: rgba(44, 95, 155, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-grey {
  background-color: rgba(92, 92, 92, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-gold {
  background-color: rgba(180, 150, 44, 0.8);
  transition: background-color 1s ease-in-out;
}

.transparent-lightGrey {
  background-color: rgba(211, 211, 211, 0.8);
  transition: background-color 1s ease-in-out;
}

aside nav ul li.active.accent-copper {
  border-left-color: #C5671C !important;
}

aside nav ul li.active.accent-copper a {
  color: #C5671C !important;
}

aside nav ul li.active.accent-brown {
  border-left-color: #8A5D1F !important;
}

aside nav ul li.active.accent-brown a {
  color: #8A5D1F !important;
}

aside nav ul li.active.accent-rasberry {
  border-left-color: #9F3658 !important;
}

aside nav ul li.active.accent-rasberry a {
  color: #9F3658 !important;
}

aside nav ul li.active.accent-orange {
  border-left-color: #D1630B !important;
}

aside nav ul li.active.accent-orange a {
  color: #D1630B !important;
}

aside nav ul li.active.accent-purple {
  border-left-color: #794FAC !important;
}

aside nav ul li.active.accent-purple a {
  color: #794FAC !important;
}

aside nav ul li.active.accent-teal {
  border-left-color: #06858B !important;
}

aside nav ul li.active.accent-teal a {
  color: #06858B !important;
}

aside nav ul li.active.accent-plum {
  border-left-color: #642867 !important;
}

aside nav ul li.active.accent-plum a {
  color: #642867 !important;
}

aside nav ul li.active.accent-red {
  border-left-color: #992E2E !important;
}

aside nav ul li.active.accent-red a {
  color: #992E2E !important;
}

aside nav ul li.active.accent-green {
  border-left-color: #1F8B53 !important;
}

aside nav ul li.active.accent-green a {
  color: #1F8B53 !important;
}

aside nav ul li.active.accent-blue {
  border-left-color: #2C5F9B !important;
}

aside nav ul li.active.accent-blue a {
  color: #2C5F9B !important;
}

aside nav ul li.active.accent-grey {
  border-left-color: #5C5C5C !important;
}

aside nav ul li.active.accent-grey a {
  color: #5C5C5C !important;
}

aside nav ul li.active.accent-gold {
  border-left-color: #B4962C !important;
}

aside nav ul li.active.accent-gold a {
  color: #B4962C !important;
}

aside nav ul li.active.accent-lightGrey {
  border-left-color: #D3D3D3 !important;
}

aside nav ul li.active.accent-lightGrey a {
  color: #D3D3D3 !important;
}

.background-copper {
  background-color: #C5671C;
}

.background-brown {
  background-color: #8A5D1F;
}

.background-rasberry {
  background-color: #9F3658;
}

.background-orange {
  background-color: #D1630B;
}

.background-purple {
  background-color: #794FAC;
}

.background-teal {
  background-color: #06858B;
}

.background-plum {
  background-color: #642867;
}

.background-red {
  background-color: #992E2E;
}

.background-green {
  background-color: #1F8B53;
}

.background-blue {
  background-color: #2C5F9B;
}

.background-grey {
  background-color: #5C5C5C;
}

.background-gold {
  background-color: #B4962C;
}

.background-lightGrey {
  background-color: #D3D3D3;
}

.text-copper {
  color: #C5671C;
}

.text-brown {
  color: #8A5D1F;
}

.text-rasberry {
  color: #9F3658;
}

.text-orange {
  color: #D1630B;
}

.text-purple {
  color: #794FAC;
}

.text-teal {
  color: #06858B;
}

.text-plum {
  color: #642867;
}

.text-red {
  color: #992E2E;
}

.text-green {
  color: #1F8B53;
}

.text-blue {
  color: #2C5F9B;
}

.text-grey {
  color: #5C5C5C;
}

.text-gold {
  color: #B4962C;
}

.text-lightGrey {
  color: #D3D3D3;
}

aside li.text-copper a {
  color: #C5671C !important;
}

aside li.text-brown a {
  color: #8A5D1F !important;
}

aside li.text-rasberry a {
  color: #9F3658 !important;
}

aside li.text-orange a {
  color: #D1630B !important;
}

aside li.text-purple a {
  color: #794FAC !important;
}

aside li.text-teal a {
  color: #06858B !important;
}

aside li.text-plum a {
  color: #642867 !important;
}

aside li.text-red a {
  color: #992E2E !important;
}

aside li.text-green a {
  color: #1F8B53 !important;
}

aside li.text-blue a {
  color: #2C5F9B !important;
}

aside li.text-grey a {
  color: #5C5C5C !important;
}

aside li.text-gold a {
  color: #B4962C !important;
}

aside li.text-lightGrey a {
  color: #D3D3D3 !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Alegreya', sans-serif;
  font-size: 19px;
  color: #000000;
}

body.no-scroll {
  overflow: hidden;
}

h1,
h2,
h3,
.caption {
  font-family: 'Alegreya', sans-serif;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 20px;
}

.caption {
  font-size: 14px;
}

.btn {
  border: 1px solid;
  box-shadow: none;
}

.btn.btn-gold {
  background-color: #B4962C;
  color: #ffffff;
  border-color: #B4962C;
}

.btn.btn-white {
  background-color: #ffffff;
  border-color: #5C5C5C;
  color: #5C5C5C;
}

.btn.btn-transparent {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;
}

.btn.btn-transparent:hover {
  box-shadow: none;
}

.btn:hover {
  box-shadow: 0 2px 4px 0 !important;
}

input[type=date].form-control, input[type=datetime-local].form-control, input[type=email].form-control, input[type=number].form-control, input[type=password].form-control, input[type=search-md].form-control, input[type=search].form-control, input[type=tel].form-control, input[type=text].form-control, input[type=time].form-control, input[type=url].form-control, textarea.md-textarea.form-control {
  border: 1px solid #D3D3D3;
}

input[type=date]:focus, input[type=datetime-local]:focus, input[type=email]:focus, input[type=number]:focus, input[type=password]:focus, input[type=search-md]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=text]:focus, input[type=time]:focus, input[type=url]:focus, textarea.md-textarea:focus {
  box-shadow: none !important;
  border: 1px solid #D3D3D3 !important;
}

hr {
  border: 1px solid #000000;
}

.breadcrumbs li a {
  color: #000000;
}

select {
  appearance: none;
  background: white;
  padding: .5rem 1rem;
  width: 100%;
  border-radius: 0;
  background-image: url("/tcm/images/select_chevron.png");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 195px center;
}

select.custom {
  display: none;
}

.nice-select span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.nice-select ul.list li {
  text-align: center;
  padding: .5rem 0;
}

.nice-select ul.list li:hover {
  background-color: #D5D5D5;
}

.nice-select ul.list li a {
  color: #5C5C5C;
  display: block;
}

.text-black {
  color: #000000;
}

ul.pagination {
  justify-content: center;
  align-items: center;
}

ul.pagination li.active span,
ul.pagination li.active a {
  background-color: #5C5C5C !important;
  color: #ffffff !important;
}

ul.pagination li span,
ul.pagination li a {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50% !important;
  font-size: 18px !important;
  color: #5C5C5C;
}

ul.pagination li.disabled span {
  background-color: transparent !important;
}

.cookie-bar-default,
.cookie-preferences-edit {
  display: none;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}

.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}

.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}

.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic-r .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.7142857143px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px;
}

.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider-r .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(5.7142857143px, -6px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(90deg);
}

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: .5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  direction: ltr;
  font-family: Avenir,"Avenir Next","Helvetica Neue","Segoe UI",Helvetica,Arial,sans-serif;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow .3s ease;
}

.plyr audio, .plyr video {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *, .plyr--full-ui ::after, .plyr--full-ui ::before {
  box-sizing: inherit;
}

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4f5b5f;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in .3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  transition: transform .4s ease-in-out;
  width: 100%;
}

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 185%;
  padding: .2em .5em;
  white-space: pre-wrap;
}

.plyr__captions .plyr__caption div {
  display: inline;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
}

.plyr__control {
  background: 0 0;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  transition: all .3s ease;
}

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0;
}

a.plyr__control {
  text-decoration: none;
}

a.plyr__control::after, a.plyr__control::before {
  display: none;
}

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none;
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #1aafff;
  color: #fff;
}

.plyr--video .plyr__control svg {
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15));
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #1aafff;
  color: #fff;
}

.plyr__control--overlaid {
  background: rgba(26, 175, 255, 0.8);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  background: #1aafff;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.plyr__controls .plyr__menu, .plyr__controls .plyr__progress, .plyr__controls .plyr__time, .plyr__controls .plyr__volume, .plyr__controls > .plyr__control {
  margin-left: 5px;
}

.plyr__controls .plyr__menu + .plyr__control, .plyr__controls .plyr__progress + .plyr__control, .plyr__controls > .plyr__control + .plyr__control, .plyr__controls > .plyr__control + .plyr__menu {
  margin-left: 2px;
}

.plyr__controls > .plyr__control:first-child, .plyr__controls > .plyr__control:first-child + [data-plyr=pause] {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__controls .plyr__menu, .plyr__controls .plyr__progress, .plyr__controls .plyr__time, .plyr__controls .plyr__volume, .plyr__controls > .plyr__control {
    margin-left: 10px;
  }
}

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4f5b5f;
  padding: 10px;
}

.plyr--video .plyr__controls {
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  transition: opacity .4s ease-in-out,transform .4s ease-in-out;
  z-index: 3;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px;
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr [data-plyr=airplay], .plyr [data-plyr=captions], .plyr [data-plyr=fullscreen], .plyr [data-plyr=pip] {
  display: none;
}

.plyr--airplay-supported [data-plyr=airplay], .plyr--captions-enabled [data-plyr=captions], .plyr--fullscreen-enabled [data-plyr=fullscreen], .plyr--pip-supported [data-plyr=pip] {
  display: inline-block;
}

.plyr__video-embed {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.plyr__video-embed iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform .3s ease;
}

.plyr__menu .plyr__control[aria-expanded=true] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  animation: plyr-popup .2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}

.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0;
}

.plyr__menu__container [role=menu] {
  padding: 7px;
}

.plyr__menu__container [role=menuitem], .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px;
}

.plyr__menu__container [role=menuitem]:first-child, .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4f5b5f;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
}

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(79, 91, 95, 0.8);
  right: 5px;
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px);
}

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(79, 91, 95, 0.8);
  left: 7px;
}

.plyr__menu__container .plyr__control--back::before {
  background: #b7c5cd;
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor;
}

.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: 7px;
}

.plyr__menu__container .plyr__control[role=menuitemradio]::after, .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  transition: all .3s ease;
  width: 16px;
}

.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform .3s ease,opacity .3s ease;
  width: 6px;
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #1aafff;
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(0, 0, 0, 0.1);
}

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none;
}

.plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 28px;
  color: #1aafff;
  display: block;
  height: 20px;
  margin: 0;
  padding: 0;
  transition: box-shadow .3s ease;
  width: 100%;
}

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: 2px;
  height: 4px;
  transition: box-shadow .3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0));
}

.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 14px;
  position: relative;
  transition: all .2s ease;
  width: 14px;
  -webkit-appearance: none;
  margin-top: -5px;
}

.plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: 2px;
  height: 4px;
  transition: box-shadow .3s ease;
  -moz-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 14px;
  position: relative;
  transition: all .2s ease;
  width: 14px;
}

.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: 2px;
  height: 4px;
}

.plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: 2px;
  height: 4px;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent;
}

.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: 2px;
  height: 4px;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: 2px;
  height: 4px;
  transition: box-shadow .3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor;
}

.plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2);
  height: 14px;
  position: relative;
  transition: all .2s ease;
  width: 14px;
  margin-top: 0;
}

.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type=range]:focus {
  outline: 0;
}

.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0;
}

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(26, 175, 255, 0.5);
  outline: 0;
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(47, 52, 61, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .2s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr__time {
  font-size: 14px;
}

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px;
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4f5b5f;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform .2s .1s ease,opacity .2s .1s ease;
  white-space: nowrap;
  z-index: 2;
}

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0, 0) scale(1);
}

.plyr--video {
  background: #000;
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  border-radius: inherit;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.plyr__progress {
  flex: 1;
  left: 7px;
  margin-right: 14px;
  position: relative;
}

.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: -7px;
  margin-right: -7px;
  width: calc(100% + 14px);
}

.plyr__progress input[type=range] {
  position: relative;
  z-index: 2;
}

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 4px;
  left: 0;
  margin-top: -2px;
  padding: 0;
  position: absolute;
  top: 50%;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0;
  transition: width .2s ease;
}

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 4px;
}

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 4px;
  transition: width .2s ease;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width .2s ease;
}

.plyr--video .plyr__progress__buffer {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.25);
}

.plyr--audio .plyr__progress__buffer {
  color: rgba(183, 197, 205, 0.66);
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(47, 52, 61, 0.6) 25%, transparent 25%, transparent 50%, rgba(47, 52, 61, 0.6) 50%, rgba(47, 52, 61, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(183, 197, 205, 0.66);
}

.plyr__volume {
  align-items: center;
  display: flex;
  flex: 1;
  position: relative;
}

.plyr__volume input[type=range] {
  margin-left: 5px;
  position: relative;
  z-index: 2;
}

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px;
  }
}

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px;
  }
}

.plyr--is-ios .plyr__volume {
  display: none !important;
}

.plyr--is-ios.plyr--vimeo [data-plyr=mute] {
  display: none !important;
}

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-moz-full-screen video {
  height: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-webkit-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-moz-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-ms-fullscreen .plyr__video-embed {
  overflow: visible;
}

.plyr:fullscreen .plyr__video-embed {
  overflow: visible;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
  }
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
  }
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
  }
  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-webkit-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-moz-full-screen video {
  height: 100%;
}

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-moz-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-ms-fullscreen .plyr__video-embed {
  overflow: visible;
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
  }
}

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr--fullscreen-fallback .plyr__video-embed {
  overflow: visible;
}

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
  }
}

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.plyr__ads::after {
  background: rgba(47, 52, 61, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3;
}

.plyr__ads::after:empty {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: 4px;
  left: 0;
  margin: -2px 0 0;
  opacity: .8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

.header {
  position: absolute;
  background: transparent;
  min-height: 100px;
  width: 100%;
  padding-top: 1.1rem;
  z-index: 99999;
  background: linear-gradient(to bottom, #000, transparent);
}

.header .logo-black {
  display: none;
}

.header .logo-hat-black,
.header .logo-hat-white {
  display: none;
  width: 110px;
}

@media screen and (max-width: 480px) {
  .header .logo-hat-white {
    display: block;
  }
  .header .logo-white {
    display: none;
  }
}

.header .hamburger {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
}

.header .hamburger.is-active {
  opacity: 1;
}

.header .hamburger:hover {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .header .hamburger {
    display: block;
  }
}

.header .hamburger .hamburger-box .hamburger-inner {
  background-color: #ffffff;
  height: 3px;
}

.header .hamburger .hamburger-box .hamburger-inner:before, .header .hamburger .hamburger-box .hamburger-inner:after {
  background-color: #ffffff;
  height: 3px;
}

@media screen and (max-width: 991px) {
  .header nav.desktop {
    display: none;
  }
}

.header nav.mobile {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 0;
  background: #5C5C5C;
  right: 0;
  left: 0;
  overflow: hidden;
  transition: all .5s ease-in-out;
}

.header nav.mobile.active {
  height: 100vh;
  transition: all .5s ease-in-out;
}

@media screen and (max-width: 991px) {
  .header nav.mobile {
    display: block;
  }
}

.header nav.mobile ul {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: block;
}

.header nav.mobile ul li {
  display: block;
  text-align: center;
  margin-bottom: 2rem;
}

.header nav.mobile ul li.support-btn {
  max-width: 180px;
  margin: 0 auto;
}

.header nav ul {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header nav ul li {
  display: inline-block;
  position: relative;
}

.header nav ul li:after {
  width: 0;
  height: 3px;
  background-color: #D1630B;
  position: absolute;
  display: block;
  content: '';
  margin: 0 auto;
  right: 0;
  left: 0;
  bottom: -8px;
}

.header nav ul li.support-btn {
  border: 1px solid #ffffff;
  padding: .2rem 1rem;
}

.header nav ul li.support-btn.active {
  border: 1px solid #D1630B;
}

.header nav ul li.support-btn.active:after {
  width: 0;
}

.header nav ul li.active:after {
  width: 80%;
}

.header nav ul li a {
  color: #ffffff;
  font-size: 16px;
}

.header.terms-and-conditions, .header.privacy, .header.cookies, .header.sitemap {
  position: relative;
  background: #5C5C5C;
  padding-bottom: 1rem;
  margin-bottom: 5rem;
}

.header.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 10px 2px 8px 0 #5C5C5C;
  padding: .3rem 0;
}

.header.sticky .logo-black {
  display: block;
}

.header.sticky .logo-white {
  display: none;
}

@media screen and (max-width: 768px) {
  .header.sticky {
    min-height: 80px;
  }
  .header.sticky .logo-hat-white {
    display: none;
  }
  .header.sticky .logo-hat-black {
    display: block;
  }
  .header.sticky .logo-black {
    display: none;
  }
}

.header.sticky ul li.support-btn {
  border: 1px solid #5C5C5C;
  padding: .2rem 1rem;
}

.header.sticky .hamburger .hamburger-box .hamburger-inner {
  background-color: #000000;
}

.header.sticky .hamburger .hamburger-box .hamburger-inner:before, .header.sticky .hamburger .hamburger-box .hamburger-inner:after {
  background-color: #000000;
}

.header.sticky .hamburger.is-active .hamburger-box .hamburger-inner {
  background-color: #ffffff;
}

.header.sticky .hamburger.is-active .hamburger-box .hamburger-inner:before, .header.sticky .hamburger.is-active .hamburger-box .hamburger-inner:after {
  background-color: #ffffff;
}

.header.sticky nav.mobile ul li a {
  color: #ffffff;
}

.header.sticky nav.mobile ul li a.support-btn {
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.header.sticky nav ul li a {
  color: #5C5C5C;
}

.header.sticky nav ul li a.support-btn {
  color: #5C5C5C !important;
  border-color: #5C5C5C !important;
}

.footer-subscribe-section {
  background: #ffffff;
  color: #000000;
  padding: 4rem 0;
}

.footer-subscribe-section .footer-subscribe-section-inner {
  max-width: 550px;
  margin: 0 auto;
}

.footer-subscribe-section .footer-subscribe-section-inner h3 {
  letter-spacing: .3rem;
  text-transform: uppercase;
}

.footer-subscribe-section .footer-subscribe-section-inner form .subscribe-input {
  flex-basis: 70%;
  height: 20px;
  margin: 0;
  padding-left: .5rem;
  padding-right: .5rem;
}

@media screen and (max-width: 480px) {
  .footer-subscribe-section .footer-subscribe-section-inner form .subscribe-input {
    flex-basis: 50%;
  }
}

.footer-subscribe-section .footer-subscribe-section-inner form .subscribe-input::placeholder {
  color: #D3D3D3;
  text-align: center;
}

.footer-subscribe-section .footer-subscribe-section-inner form .subscribe-btn {
  padding: .6rem 1.8rem;
}

.footer-subscribe-section .footer-subscribe-section-inner form .gdpr-check .form-check-input {
  display: inline-block;
  float: left;
  margin-top: 5px;
}

.footer-subscribe-section .footer-subscribe-section-inner form .gdpr-check .form-check-label {
  font-size: 14px;
}

.footer-subscribe-section .footer-subscribe-section-inner .response.show {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

footer.footer {
  background-color: #5C5C5C;
  padding: 4rem 0;
  color: #ffffff;
}

@media screen and (max-width: 991px) {
  footer.footer {
    padding-bottom: 1rem;
  }
}

footer.footer .footer-logo {
  width: 70%;
}

@media screen and (max-width: 991px) {
  footer.footer .footer-logo {
    width: 20%;
    margin-bottom: 2rem;
  }
}

footer.footer .footer-section:not(:last-child) {
  border-right: 1px solid #ffffff;
}

@media screen and (max-width: 991px) {
  footer.footer .footer-section:not(:last-child) {
    border-right: none;
  }
}

footer.footer .footer-section .address-info,
footer.footer .footer-section .contact-info {
  font-size: 16px;
  margin-bottom: 0;
}

footer.footer .footer-section .address-info a,
footer.footer .footer-section .contact-info a {
  color: #ffffff;
}

footer.footer .footer-section .contact {
  margin-top: 1rem;
}

@media screen and (max-width: 991px) {
  footer.footer .footer-section {
    text-align: center;
  }
  footer.footer .footer-section:last-child {
    display: none;
  }
  footer.footer .footer-section span {
    display: block;
  }
}

footer.footer .footer-nav ul {
  padding: 0;
  list-style-type: none;
}

footer.footer .footer-nav ul li {
  text-decoration: none;
  display: inline-block;
  width: calc(50% - 15px);
  margin-bottom: .2rem;
}

footer.footer .footer-nav ul li a {
  color: #ffffff;
  font-size: 16px;
}

.footer-bottom {
  background-color: #313131;
  padding: .8rem 0;
}

.footer-bottom nav ul {
  padding: 0;
  text-align: center;
  list-style-type: none;
  margin: 0;
}

.footer-bottom nav ul li {
  display: inline-block;
  margin-right: 3rem;
  font-size: 16px;
}

.footer-bottom nav ul li a {
  color: #D3D3D3;
}

.info-blocks {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .info-blocks {
    display: block;
  }
}

.info-blocks .block-link {
  color: #ffffff;
}

.info-blocks .block {
  padding: 2rem;
  flex-basis: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #ffffff;
  position: relative;
  height: 539px;
}

.info-blocks .block.block-1 {
  background-image: url("/tcm/images/whats_on_block_1_bg.png");
}

.info-blocks .block.block-2 {
  background-image: url("/tcm/images/whats_on_block_2_bg.png");
}

.info-blocks .block.block-3 {
  background-image: url("/tcm/images/whats_on_block_3_bg.png");
}

.info-blocks .block.block-4 {
  background-image: url("/tcm/images/whats_on_block_4_bg.png");
}

.info-blocks .block .block-content {
  text-align: center;
  position: absolute;
  z-index: 1;
  width: calc(100% - 50px);
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.info-blocks .block .block-content hr {
  border-color: #ffffff;
  width: 75%;
}

.info-blocks .block .block-content p {
  height: auto;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all .5s ease-in-out;
  margin-bottom: 2rem;
}

.info-blocks .block .block-content .elipses {
  opacity: 1;
  transition: all .5s ease-in-out;
  font-size: 50px;
}

.info-blocks .block .block-content .btn {
  max-width: 150px;
}

.info-blocks .block .block-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.info-blocks .block:hover p {
  height: auto;
  max-height: 400px;
  overflow: auto;
  opacity: 1;
}

.info-blocks .block:hover .elipses {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  font-size: 19px;
}

.fact-slider-section {
  padding: 3rem 0rem;
}

.fact-slider-section h3 {
  text-transform: uppercase;
  letter-spacing: .2rem;
  color: #000000;
}

.fact-slider-section hr {
  width: 280px;
  border-color: #000000;
}

.fact-slider-section .fact-slider-wrapper {
  position: relative;
}

.fact-slider-section .fact-slider-wrapper .fact-slider .slide .slide-content {
  max-width: 550px;
  margin: 0 auto;
  color: #000000;
}

.fact-slider-section .fact-slider-wrapper .prev-button,
.fact-slider-section .fact-slider-wrapper .next-button {
  display: block;
  position: absolute;
  top: 50%;
}

.fact-slider-section .fact-slider-wrapper .prev-button {
  left: 0;
}

.fact-slider-section .fact-slider-wrapper .next-button {
  right: 0;
}

.image-with-content-box {
  width: 100%;
  height: 548px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 11rem;
  background-position: center center;
  position: relative;
}

.image-with-content-box .content-box {
  padding: 2rem 2rem 3rem 2rem;
  color: #ffffff;
  position: absolute;
  right: 0;
  height: 429px;
}

.image-with-content-box .content-box .content-box-subtitle {
  letter-spacing: .2rem;
  margin-bottom: 1.5rem;
}

.image-with-content-box .content-box .content-box-subtitle h3 {
  font-size: 23px;
  text-transform: uppercase;
}

.image-with-content-box .content-box .content-box-subtitle hr {
  border-color: #ffffff;
}

@media screen and (max-width: 480px) {
  .image-with-content-box .content-box {
    width: 100vw;
    margin-left: -50vw;
    position: relative;
    left: 50%;
  }
}

@media screen and (max-width: 768px) {
  .image-with-content-box {
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 480px) {
  .image-with-content-box {
    padding-top: 28rem;
    margin-bottom: 22rem;
  }
}

.half-width-title-with-text {
  color: #000000;
  padding: 2rem 0;
}

.half-width-title-with-text .breadcrumbs {
  margin-top: 0;
}

.full-width-image-with-title {
  width: 100%;
  height: 365px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media screen and (max-width: 768px) {
  .full-width-image-with-title {
    height: 230px;
  }
}

.page-intro h1 {
  color: #000000;
}

.page-intro-with-image {
  text-align: center;
  padding-bottom: 2rem;
}

.page-intro-with-image .breadcrumbs {
  text-align: left;
}

.page-intro-with-image .featured-image {
  width: 100%;
  height: 548px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@media screen and (max-width: 768px) {
  .page-intro-with-image .featured-image {
    height: 230px;
  }
}

.page-intro-with-image .title {
  max-width: 630px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.page-intro-with-image .title h1 {
  color: #000000;
}

.page-intro-with-image p {
  color: #000000;
}

.discover-more {
  padding: 4rem 0;
  background-color: #F2F2F2;
}

.discover-more .title {
  max-width: 400px;
  margin: 0 auto;
}

.discover-more .title h3 {
  text-align: center;
  letter-spacing: .3rem;
  text-transform: uppercase;
  color: #000000;
}

.image-stack img {
  margin-bottom: .5rem !important;
}

.homepage-hero-slider-wrapper {
  position: relative;
  top: 25%;
}

@media screen and (max-width: 991px) {
  .homepage-hero-slider-wrapper {
    top: 50%;
  }
}

@media screen and (max-width: 480px) {
  .homepage-hero-slider-wrapper {
    z-index: 9999;
    width: 100vw;
    left: -15px;
    top: 140px;
  }
}

.homepage-hero-slider-wrapper .slide {
  padding: 1rem 2rem;
}

.homepage-hero-slider-wrapper .slide .slider-subtitle {
  text-transform: uppercase;
  letter-spacing: 5px;
}

.homepage-hero-slider-wrapper .slide .hero-slider-anchor {
  font-weight: bold;
}

.homepage-hero-slider-wrapper .homepage-hero-slider {
  color: #ffffff;
}

.homepage-hero-slider-wrapper .homepage-hero-slider hr {
  border-color: #ffffff;
}

.homepage-hero-slider-wrapper .slider-content .context-info {
  margin-bottom: 2rem;
}

.homepage-hero-slider-wrapper .slider-content .context-info p {
  font-weight: bold;
  margin-bottom: 0;
}

.homepage-hero-slider-wrapper .slider-buttons {
  display: flex;
  justify-content: flex-end;
}

.homepage-hero-slider-wrapper .slider-buttons i {
  margin-right: .5rem;
}

@media screen and (max-width: 991px) {
  .homepage-hero-slider-wrapper .slider-image {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .homepage-hero-slider-wrapper .slider-image,
  .homepage-hero-slider-wrapper .main-content {
    display: none;
  }
}

.what-to-expect {
  position: relative;
  background-image: url("/tcm/images/what_to_expect_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 0;
  color: #000000;
}

@media screen and (max-width: 320px) {
  .what-to-expect {
    margin-top: 15rem;
  }
}

.what-to-expect:before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.what-to-expect hr {
  border-color: #000000;
}

.what-to-expect .content {
  position: relative;
  z-index: 1;
}

.what-to-expect .content .btn {
  box-shadow: none;
}

.what-to-expect .content .btn.read-more {
  margin-top: 2rem;
  max-width: 150px;
  border-color: #5C5C5C;
  color: #5C5C5C !important;
}

.social-section {
  background-image: url("/tcm/images/social-section-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 4rem 0;
}

.social-section h1 {
  color: #000000;
}

.social-section:before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.social-section .btn.btn-twitter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #1DA1F2;
  color: #ffffff;
  text-align: center;
  text-transform: none;
  font-size: 18px;
  border-color: #1DA1F2;
  margin: 0 0 1rem 0;
}

.social-section .btn.btn-twitter img {
  margin-bottom: 0 !important;
  margin-right: .5rem;
}

.social-section .twitter-timeline .timeline-Widget .timeline-Footer {
  display: none;
}

.social-section .facebook-section, .social-section .instagram-section {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  color: #ffffff;
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .social-section .facebook-section, .social-section .instagram-section {
    background-image: none;
  }
}

.social-section .facebook-section:before, .social-section .instagram-section:before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  margin: 0 auto;
}

.social-section .facebook-section .content, .social-section .instagram-section .content {
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  justify-content: center;
  color: #ffffff;
}

.social-section .facebook-section .content img, .social-section .instagram-section .content img {
  margin-right: .5rem;
}

@media screen and (max-width: 480px) {
  .social-section .facebook-section .content img, .social-section .instagram-section .content img {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 480px) {
  .social-section .facebook-section .content, .social-section .instagram-section .content {
    padding: .5rem 0;
  }
}

.social-section .facebook-section {
  background-image: url("/tcm/images/facebook_bg_image.png");
}

@media screen and (max-width: 480px) {
  .social-section .facebook-section {
    background-color: #3C5A99;
  }
}

.social-section .facebook-section:before {
  background-color: rgba(57, 87, 155, 0.7);
}

.social-section .instagram-section {
  background-image: url("/tcm/images/facebook_bg_image.png");
  background-image: url("/tcm/images/instagram_bg_image.png");
}

@media screen and (max-width: 480px) {
  .social-section .instagram-section {
    background-color: #E1306C;
  }
}

.social-section .instagram-section:before {
  background-color: rgba(225, 48, 108, 0.7);
}

.home .homepage-hero {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: relative;
}

@media screen and (min-width: 992px) and (max-width: 1280px) {
  .home .homepage-hero {
    height: 820px;
  }
}

.home .homepage-hero .explore-more {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 480px) {
  .home .homepage-hero .explore-more {
    display: none;
  }
}

.dashboard {
  display: flex;
  border-bottom: 1px solid #D3D3D3;
}

@media screen and (max-width: 991px) {
  .dashboard {
    display: block;
  }
}

.dashboard aside {
  background-color: #f5f5f5;
  flex-basis: 25%;
  align-self: flex-start;
}

@media screen and (max-width: 991px) {
  .dashboard aside {
    margin-bottom: 1rem;
  }
}

.dashboard aside nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.dashboard aside nav ul li {
  border-left: 8px solid transparent;
}

.dashboard aside nav ul li a {
  padding: .8rem 2rem;
  color: #000000;
  display: block;
}

.dashboard .dashboard-content {
  padding: 3rem 2rem;
  flex-basis: 75%;
  background-color: #F2F2F2;
  color: #000000;
}

.dashboard .dashboard-content .filter {
  margin-bottom: 3rem;
}

.dashboard .dashboard-content .filter select {
  appearance: none;
  background: white;
  padding: .5rem 1rem;
  width: 100%;
  border-radius: 0;
  background-image: url("/tcm/images/select_chevron.png");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 195px center;
}

.default .dashboard {
  border-bottom: 0;
}

.error-404 {
  width: 100%;
  height: 100vh;
  background-image: url("/tcm/images/cromwell_homepage_hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.error-404 .error-box {
  padding: 2rem;
  position: absolute;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  right: 20%;
}

.error-404 .error-box p {
  width: 45%;
}

@media screen and (max-width: 480px) {
  .whats-on .image-with-content-box {
    background-position: -377px center;
  }
}

.whats-on .event-card {
  background-color: #ffffff;
  height: 100%;
}

.whats-on .event-card .events-card-image {
  width: 100%;
  height: 162px;
  background-repeat: no-repeat;
  background-size: cover;
}

.whats-on .event-card .events-card-body {
  padding: 1.5rem 1rem;
}

.whats-on .event-card .events-card-body .btn-transparent {
  border-color: #5C5C5C;
  color: #5C5C5C !important;
  margin: 0;
}

.whats-on .event-card .events-card-body h2 {
  margin-bottom: 2rem;
}

.whats-on .event-card .events-card-body .btn {
  padding: .3rem 1rem;
  text-transform: none;
  display: block;
  margin: 0 auto;
  font-size: 16px;
}

.whats-on .event-card .events-card-body .excerpt {
  margin-bottom: .3rem;
  margin-top: .5rem;
  font-weight: bold;
}

.whats-on .event-card .events-card-body .dates,
.whats-on .event-card .events-card-body .times {
  color: #5C5C5C;
}

.whats-on .content-box {
  background-color: rgba(180, 150, 44, 0.8);
}

@media screen and (max-width: 480px) {
  .whats-on-single .image-with-content-box {
    margin-bottom: 0;
  }
}

.whats-on-single .btn.btn-transparent {
  border-color: #5C5C5C;
  color: #5C5C5C !important;
}

.whats-on-single .btn i {
  margin-right: 1rem;
}

.key-collections .key-collections-wrapper .key-collection {
  border-bottom: 1px solid #D8D8D8;
  color: #000000;
  padding-bottom: 3rem;
  padding-top: 2rem;
}

.key-collections .key-collections-wrapper .key-collection:last-child {
  border-bottom: 0;
}

.key-collections .key-collections-wrapper .key-collection h2 {
  margin-bottom: 1rem;
}

.key-collections .modal-key-figures {
  z-index: 9999999;
}

.modal-backdrop.show {
  opacity: 0.8;
  z-index: 999999;
}

.key-figures .figure-info p {
  margin-bottom: 0;
  font-weight: bold;
}

.key-figures .dashboard .dashboard-content {
  background-color: #ffffff;
  padding-top: 0;
  padding-bottom: 0;
}

.key-figures .page-intro-with-image .title {
  width: 100%;
  max-width: 100%;
  text-align: left;
}

.key-figures .key-figure {
  padding-bottom: 2rem;
}

.key-figures .key-figure:not(:last-child) {
  border-bottom: 1px solid #D3D3D3;
  margin-bottom: 2rem;
}

.key-figures .key-figure .key-significance {
  margin-top: 2rem;
}

.learning-hub-resources .dashboard {
  border-bottom: 0;
}

.learning-hub-resources .dashboard .dashboard-content p {
  width: 100%;
}

.learning-hub-resources .resources .resource-card .resource-card-header,
.learning-hub-resources .resources .resource-card .resource-card-body {
  padding: .4rem .8rem;
}

.learning-hub-resources .resources .resource-card .resource-card-header {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  align-items: center;
  font-size: 16px;
}

.learning-hub-resources .resources .resource-card .resource-card-body {
  background-color: #ffffff;
}

.learning-hub-resources .resources .resource-card .resource-card-body h4 {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: .8rem;
}

.learning-hub-resources .resources .resource-card .resource-card-body .buttons {
  display: flex;
  justify-content: space-between;
  margin-top: .5rem;
}

.learning-hub-resources .resources .resource-card .resource-card-body .buttons .btn {
  padding: .5rem .5rem;
  flex-basis: 50%;
  margin-left: 0;
  margin-right: 0;
}

.learning-hub-resources .resources .resource-card .resource-card-body .buttons .btn:first-child {
  margin-right: 15px;
}

.learning-hub-resources .resources .resource-card .resource-card-body .content {
  font-size: 16px;
  margin-bottom: .8rem;
}

.learning-hub-resources .session-modal {
  z-index: 9999999;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  height: 100%;
}

.learning-hub .info-blocks .block {
  flex-basis: 33.33%;
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  .learning-hub .info-blocks .block {
    flex-basis: 50%;
  }
}

.learning-hub .content-box {
  background-color: rgba(153, 46, 46, 0.8);
  min-height: 429px;
  height: auto;
}

@media screen and (max-width: 1199px) {
  .learning-hub .content-box {
    top: -45px;
  }
}

@media screen and (max-width: 439px) {
  .learning-hub .content-box {
    top: -145px;
  }
}

.significance .page-intro-with-image {
  padding-bottom: 1rem;
}

.significance .page-intro-with-image .title {
  max-width: 100%;
  text-align: left;
}

.significance .dashboard aside {
  background-color: #F2F2F2;
}

.significance .dashboard .dashboard-content {
  background-color: #ffffff;
  padding: 0 2rem 2rem 2rem;
}

.about-us {
  color: #000000;
}

.about-us .main-holder {
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: 2rem;
}

.about-us .page-intro-with-image {
  margin-bottom: 0;
  padding-bottom: 0rem;
}

.about-us h1 {
  color: #000000;
}

.about-us .side-images img {
  margin-bottom: 2.5rem !important;
}

.about-us .dashboard-content {
  padding-top: 0;
}

.plan-a-visit h1 {
  color: #000000;
}

.plan-a-visit .dashboard-content {
  color: #000000;
}

.plan-a-visit .contact-info {
  display: flex;
  flex-wrap: wrap;
}

.plan-a-visit .contact-info address span {
  display: block;
}

.plan-a-visit .contact-info a {
  color: #000000;
}

.plan-a-visit .contact-info p {
  word-wrap: break-word;
}

.plan-a-visit #map {
  width: 100%;
  height: 260px;
}

.plan-a-visit .info-accordions {
  margin-top: 2rem;
}

.plan-a-visit .info-accordions .accordion:not(:last-child) {
  margin-bottom: 2rem;
}

.plan-a-visit .info-accordions .accordion .accordion-header {
  color: #000000;
  border-bottom: 1px solid #000000;
  position: relative;
  padding-bottom: .5rem;
  cursor: pointer;
  margin-bottom: 1rem;
}

.plan-a-visit .info-accordions .accordion .accordion-header i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

.plan-a-visit .info-accordions .accordion .accordion-body {
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition: max-height .5s ease-in-out;
}

.plan-a-visit .info-accordions .accordion .accordion-body.open {
  max-height: 1000px;
  transition: max-height .5s ease-in-out;
}

.about-huntingdon .dashboard {
  border-bottom: 1px solid #D3D3D3;
}

.about-huntingdon .dashboard .dashboard-content {
  background-color: #ffffff;
  padding-top: 0;
}

.line-t1 {
  left: 75px;
}

@media screen and (max-width: 1199px) {
  .line-t1 {
    left: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t1 {
    left: 55px;
  }
}

@media screen and (max-width: 768px) {
  .line-t1 {
    top: 60px !important;
    left: unset;
  }
}

.line-t2 {
  left: 150px;
}

@media screen and (max-width: 1199px) {
  .line-t2 {
    left: 120px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t2 {
    left: 110px;
  }
}

@media screen and (max-width: 768px) {
  .line-t2 {
    top: 120px !important;
    left: unset;
  }
}

.line-t3 {
  left: 225px;
}

@media screen and (max-width: 1199px) {
  .line-t3 {
    left: 180px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t3 {
    left: 165px;
  }
}

@media screen and (max-width: 768px) {
  .line-t3 {
    top: 180px !important;
    left: unset;
  }
}

.line-t4 {
  left: 300px;
}

@media screen and (max-width: 1199px) {
  .line-t4 {
    left: 240px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t4 {
    left: 220px;
  }
}

@media screen and (max-width: 768px) {
  .line-t4 {
    top: 240px !important;
    left: unset;
  }
}

.line-t5 {
  left: 375px;
}

@media screen and (max-width: 1199px) {
  .line-t5 {
    left: 300px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t5 {
    left: 275px;
  }
}

@media screen and (max-width: 768px) {
  .line-t5 {
    top: 300px !important;
    left: unset;
  }
}

.line-t6 {
  left: 450px;
}

@media screen and (max-width: 1199px) {
  .line-t6 {
    left: 360px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t6 {
    left: 330px;
  }
}

@media screen and (max-width: 768px) {
  .line-t6 {
    top: 360px !important;
    left: unset;
  }
}

.line-t7 {
  left: 525px;
}

@media screen and (max-width: 1199px) {
  .line-t7 {
    left: 420px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t7 {
    left: 385px;
  }
}

@media screen and (max-width: 768px) {
  .line-t7 {
    top: 420px !important;
    left: unset;
  }
}

.line-t8 {
  left: 600px;
}

@media screen and (max-width: 1199px) {
  .line-t8 {
    left: 480px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t8 {
    left: 440px;
  }
}

@media screen and (max-width: 768px) {
  .line-t8 {
    top: 480px !important;
    left: unset;
  }
}

.line-t9 {
  left: 675px;
}

@media screen and (max-width: 1199px) {
  .line-t9 {
    left: 540px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t9 {
    left: 495px;
  }
}

@media screen and (max-width: 768px) {
  .line-t9 {
    top: 540px !important;
    left: unset;
  }
}

.line-t10 {
  left: 750px;
}

@media screen and (max-width: 1199px) {
  .line-t10 {
    left: 600px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t10 {
    left: 550px;
  }
}

@media screen and (max-width: 768px) {
  .line-t10 {
    top: 600px !important;
    left: unset;
  }
}

.line-t11 {
  left: 825px;
}

@media screen and (max-width: 1199px) {
  .line-t11 {
    left: 660px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t11 {
    left: 605px;
  }
}

@media screen and (max-width: 768px) {
  .line-t11 {
    top: 660px !important;
    left: unset;
  }
}

.line-t12 {
  left: 900px;
}

@media screen and (max-width: 1199px) {
  .line-t12 {
    left: 720px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t12 {
    left: 660px;
  }
}

@media screen and (max-width: 768px) {
  .line-t12 {
    top: 720px !important;
    left: unset;
  }
}

.line-t13 {
  left: 975px;
}

@media screen and (max-width: 1199px) {
  .line-t13 {
    left: 780px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t13 {
    left: 715px;
  }
}

@media screen and (max-width: 768px) {
  .line-t13 {
    top: 780px !important;
    left: unset;
  }
}

.line-t14 {
  left: 1050px;
}

@media screen and (max-width: 1199px) {
  .line-t14 {
    left: 840px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t14 {
    left: 770px;
  }
}

@media screen and (max-width: 768px) {
  .line-t14 {
    top: 840px !important;
    left: unset;
  }
}

.line-t15 {
  left: 1125px;
}

@media screen and (max-width: 1199px) {
  .line-t15 {
    left: 900px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t15 {
    left: 825px;
  }
}

@media screen and (max-width: 768px) {
  .line-t15 {
    top: 900px !important;
    left: unset;
  }
}

.line-t16 {
  left: 1200px;
}

@media screen and (max-width: 1199px) {
  .line-t16 {
    left: 960px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t16 {
    left: 880px;
  }
}

@media screen and (max-width: 768px) {
  .line-t16 {
    top: 960px !important;
    left: unset;
  }
}

.line-t17 {
  left: 1275px;
}

@media screen and (max-width: 1199px) {
  .line-t17 {
    left: 1020px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t17 {
    left: 935px;
  }
}

@media screen and (max-width: 768px) {
  .line-t17 {
    top: 1020px !important;
    left: unset;
  }
}

.line-t18 {
  left: 1350px;
}

@media screen and (max-width: 1199px) {
  .line-t18 {
    left: 1080px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t18 {
    left: 990px;
  }
}

@media screen and (max-width: 768px) {
  .line-t18 {
    top: 1080px !important;
    left: unset;
  }
}

.line-t19 {
  left: 1425px;
}

@media screen and (max-width: 1199px) {
  .line-t19 {
    left: 1140px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t19 {
    left: 1045px;
  }
}

@media screen and (max-width: 768px) {
  .line-t19 {
    top: 1140px !important;
    left: unset;
  }
}

.line-t20 {
  left: 1500px;
}

@media screen and (max-width: 1199px) {
  .line-t20 {
    left: 1200px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t20 {
    left: 1100px;
  }
}

@media screen and (max-width: 768px) {
  .line-t20 {
    top: 1200px !important;
    left: unset;
  }
}

.line-t21 {
  left: 1575px;
}

@media screen and (max-width: 1199px) {
  .line-t21 {
    left: 1260px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t21 {
    left: 1155px;
  }
}

@media screen and (max-width: 768px) {
  .line-t21 {
    top: 1260px !important;
    left: unset;
  }
}

.line-t22 {
  left: 1650px;
}

@media screen and (max-width: 1199px) {
  .line-t22 {
    left: 1320px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t22 {
    left: 1210px;
  }
}

@media screen and (max-width: 768px) {
  .line-t22 {
    top: 1320px !important;
    left: unset;
  }
}

.line-t23 {
  left: 1725px;
}

@media screen and (max-width: 1199px) {
  .line-t23 {
    left: 1380px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t23 {
    left: 1265px;
  }
}

@media screen and (max-width: 768px) {
  .line-t23 {
    top: 1380px !important;
    left: unset;
  }
}

.line-t24 {
  left: 1800px;
}

@media screen and (max-width: 1199px) {
  .line-t24 {
    left: 1440px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t24 {
    left: 1320px;
  }
}

@media screen and (max-width: 768px) {
  .line-t24 {
    top: 1440px !important;
    left: unset;
  }
}

.line-t25 {
  left: 1875px;
}

@media screen and (max-width: 1199px) {
  .line-t25 {
    left: 1500px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t25 {
    left: 1375px;
  }
}

@media screen and (max-width: 768px) {
  .line-t25 {
    top: 1500px !important;
    left: unset;
  }
}

.line-t26 {
  left: 1950px;
}

@media screen and (max-width: 1199px) {
  .line-t26 {
    left: 1560px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t26 {
    left: 1430px;
  }
}

@media screen and (max-width: 768px) {
  .line-t26 {
    top: 1560px !important;
    left: unset;
  }
}

.line-t27 {
  left: 2025px;
}

@media screen and (max-width: 1199px) {
  .line-t27 {
    left: 1620px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t27 {
    left: 1485px;
  }
}

@media screen and (max-width: 768px) {
  .line-t27 {
    top: 1620px !important;
    left: unset;
  }
}

.line-t28 {
  left: 2100px;
}

@media screen and (max-width: 1199px) {
  .line-t28 {
    left: 1680px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t28 {
    left: 1540px;
  }
}

@media screen and (max-width: 768px) {
  .line-t28 {
    top: 1680px !important;
    left: unset;
  }
}

.line-t29 {
  left: 2175px;
}

@media screen and (max-width: 1199px) {
  .line-t29 {
    left: 1740px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t29 {
    left: 1595px;
  }
}

@media screen and (max-width: 768px) {
  .line-t29 {
    top: 1740px !important;
    left: unset;
  }
}

.line-t30 {
  left: 2250px;
}

@media screen and (max-width: 1199px) {
  .line-t30 {
    left: 1800px;
  }
}

@media screen and (max-width: 1024px) {
  .line-t30 {
    left: 1650px;
  }
}

@media screen and (max-width: 768px) {
  .line-t30 {
    top: 1800px !important;
    left: unset;
  }
}

.key-events .dashboard-content {
  background-color: #ffffff;
  padding-top: 0;
}

@media screen and (max-width: 991px) {
  .key-events .dashboard-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.key-events .timeline {
  position: relative;
  height: 325px;
  background-color: #f5f5f5;
  padding: 0 3rem;
  overflow: auto;
  margin-bottom: 2rem;
}

@media screen and (max-width: 768px) {
  .key-events .timeline {
    height: 650px;
  }
}

.key-events .timeline .main-line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100vw;
  height: 2px;
  background-color: #D3D3D3;
}

@media screen and (max-width: 480px) {
  .key-events .timeline .main-line {
    top: 10%;
    transform: translateY(0);
  }
}

.key-events .timeline .main-line:after, .key-events .timeline .main-line:before {
  position: absolute;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: #D3D3D3;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.key-events .timeline .main-line:before {
  right: 0;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .main-line:before {
    bottom: 0;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
  }
}

.key-events .timeline .main-line:after {
  left: 0;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .main-line:after {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.key-events .timeline .main-line .start-date,
.key-events .timeline .main-line .end-date {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 35px;
  font-size: 14px;
}

.key-events .timeline .main-line .start-date {
  left: -40px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .main-line .start-date {
    top: 0;
  }
}

.key-events .timeline .main-line .end-date {
  right: -40px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .main-line .end-date {
    bottom: 0;
    top: unset;
  }
}

.key-events .timeline .main-line .line {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .main-line {
    height: 2000px;
    width: 2px !important;
    left: 50%;
  }
}

.key-events .timeline .line {
  position: absolute;
  width: auto;
}

@media screen and (max-width: 480px) {
  .key-events .timeline .line {
    width: 100px;
  }
}

.key-events .timeline .line p {
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
}

.key-events .timeline .line p.date {
  font-weight: bold;
}

.key-events .timeline .line.line-top {
  top: -95px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .line.line-top {
    left: -145px;
    top: unset;
  }
}

.key-events .timeline .line.line-top:after {
  top: 65px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .line.line-top:after {
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    width: 32px;
    left: unset;
    right: -48px;
  }
}

.key-events .timeline .line.line-top:nth-of-type(4n + 3), .key-events .timeline .line.line-top:nth-of-type(4n + 4) {
  top: -131px;
}

.key-events .timeline .line.line-top:nth-of-type(4n + 3):after, .key-events .timeline .line.line-top:nth-of-type(4n + 4):after {
  height: 68px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .line.line-top:nth-of-type(4n + 3):after, .key-events .timeline .line.line-top:nth-of-type(4n + 4):after {
    height: 2px;
    width: 32px;
    right: -74px;
  }
}

.key-events .timeline .line.line-top:nth-of-type(4n + 3):before, .key-events .timeline .line.line-top:nth-of-type(4n + 4):before {
  bottom: -95px;
}

.key-events .timeline .line.line-bottom {
  top: 53px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .line.line-bottom {
    left: 45px;
  }
}

.key-events .timeline .line.line-bottom:after {
  bottom: 63px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .line.line-bottom:after {
    width: 32px;
    height: 2px;
    top: 50%;
    left: -29px;
  }
}

.key-events .timeline .line.line-bottom:before {
  top: -56px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .line.line-bottom:before {
    top: 50%;
    left: -48px;
  }
}

.key-events .timeline .line.line-bottom:nth-of-type(4n + 3), .key-events .timeline .line.line-bottom:nth-of-type(4n + 4) {
  top: 90px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .line.line-bottom:nth-of-type(4n + 3), .key-events .timeline .line.line-bottom:nth-of-type(4n + 4) {
    left: 45px;
  }
}

.key-events .timeline .line.line-bottom:nth-of-type(4n + 3):after, .key-events .timeline .line.line-bottom:nth-of-type(4n + 4):after {
  height: 69px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .line.line-bottom:nth-of-type(4n + 3):after, .key-events .timeline .line.line-bottom:nth-of-type(4n + 4):after {
    width: 32px;
    height: 2px;
    top: 50%;
    left: -29px;
  }
}

.key-events .timeline .line.line-bottom:nth-of-type(4n + 3):before, .key-events .timeline .line.line-bottom:nth-of-type(4n + 4):before {
  top: -92px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline .line.line-bottom:nth-of-type(4n + 3):before, .key-events .timeline .line.line-bottom:nth-of-type(4n + 4):before {
    top: 50%;
    left: -48px;
  }
}

.key-events .timeline .line:after {
  position: absolute;
  width: 2px;
  height: 32px;
  content: '';
  display: block;
  background-color: #D3D3D3;
  left: 50%;
  transform: translateX(-50%);
}

.key-events .timeline .line:before {
  content: '';
  position: absolute;
  background-color: #D3D3D3;
  width: 8px;
  height: 8px;
  display: block;
  bottom: -58px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 768px) {
  .key-events .timeline .line:before {
    bottom: unset;
    left: unset;
    transform: translateY(-50%);
    top: 50%;
    right: -50px;
  }
}

.key-events .timeline .timeline-model {
  position: absolute;
  height: 0;
  background: #ffffff;
  left: 0;
  right: 0;
  opacity: 0;
  padding: 1rem;
  transition: all .5s ease-in-out;
  top: 50%;
  width: calc(100% - 3rem);
  margin: 0 auto;
  transform: translateY(-50%);
  overflow: auto;
}

.key-events .timeline .timeline-model.open {
  position: sticky;
  opacity: 1;
  height: calc(100% - 40%);
  z-index: 9999;
  transition: all .5s ease-in-out;
}

.key-events .timeline .timeline-model .date {
  margin-bottom: 0;
  font-weight: bold;
}

.key-events .timeline.timeline-mobile {
  height: 600px;
  display: none;
}

@media screen and (max-width: 768px) {
  .key-events .timeline.timeline-mobile {
    display: block;
  }
}

.key-events .timeline.timeline-mobile .main-line {
  width: 2px;
  height: calc(100% - 6rem);
  left: 50%;
}

.key-events .timeline.timeline-mobile .main-line:before {
  left: 0;
  top: 0;
  right: 50%;
  transform: translateX(-50%);
}

.key-events .timeline.timeline-mobile .main-line:after {
  left: 0;
  top: unset;
  bottom: 0;
  right: 50%;
  transform: translateX(-50%);
}

.key-events .timeline.timeline-mobile .main-line .start-date,
.key-events .timeline.timeline-mobile .main-line .end-date {
  width: 65px;
  left: 50%;
  transform: translateX(-50%);
}

.key-events .timeline.timeline-mobile .main-line .start-date {
  top: -28px;
}

.key-events .timeline.timeline-mobile .main-line .end-date {
  bottom: -28px;
  top: unset;
}

.key-events .timeline.timeline-mobile .main-line .line-top {
  left: -250px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline.timeline-mobile .main-line .line-top {
    left: -168px;
  }
}

.key-events .timeline.timeline-mobile .main-line .line-top:after {
  top: 50%;
  transform: translateY(-50%);
  width: 127px;
  height: 2px;
  left: 125px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline.timeline-mobile .main-line .line-top:after {
    width: 45px;
  }
}

.key-events .timeline.timeline-mobile .main-line .line-top:before {
  transform: translateY(-50%);
  top: 50%;
  left: unset;
  right: -155px;
}

@media screen and (max-width: 768px) {
  .key-events .timeline.timeline-mobile .main-line .line-top:before {
    right: -73px;
  }
}

.key-events .timeline.timeline-mobile .main-line .line-bottom {
  right: -250px;
  left: unset;
}

@media screen and (max-width: 768px) {
  .key-events .timeline.timeline-mobile .main-line .line-bottom {
    right: -168px;
  }
}

.key-events .timeline.timeline-mobile .main-line .line-bottom:after {
  width: 124px;
  height: 2px;
  top: 50%;
  left: unset;
  right: 128px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .key-events .timeline.timeline-mobile .main-line .line-bottom:after {
    width: 42px;
  }
}

.key-events .timeline.timeline-mobile .main-line .line-bottom:before {
  top: 50%;
  right: 247px;
  left: unset;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .key-events .timeline.timeline-mobile .main-line .line-bottom:before {
    right: 165px;
  }
}

.donate .donate-box {
  width: 100%;
  padding: 2rem;
  position: relative;
  color: #ffffff;
  z-index: 1;
  text-align: center;
  margin-top: 4rem;
}

@media screen and (max-width: 768px) {
  .donate .donate-box {
    margin-top: 1rem;
  }
}

.donate .donate-box p {
  font-size: 22px;
}

.donate .donate-box .donate-box-overlay {
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.volunteer .image-stack {
  margin-top: 4rem;
}

.volunteer .content {
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: 3rem;
}

.info-page {
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: 2rem;
}

.sitemap a {
  color: #5C5C5C;
}

.lord-protector .dashboard-content,
.mp .dashboard-content,
.soldier .dashboard-content {
  background-color: #ffffff;
}

@media (max-width: 480px) {
  .adopt-an-object .whats-on .image-with-content-box {
    background-position: center;
  }
}

.adopt-an-object .adoption-items {
  margin-bottom: 40px;
}

.adopt-an-object .adoption-items .object {
  margin-bottom: 80px;
}

.adopt-an-object .adoption-items .object .image-wrap {
  height: 300px;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.adopt-an-object .adoption-items .object .image-wrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  max-width: 100%;
}

.adopt-an-object .adoption-items .object .image-wrap .adopted {
  font-family: sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  color: #992E2E;
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(10deg);
  opacity: 0.8;
}

.adopt-an-object .adoption-items .object .image-wrap .adopted:before, .adopt-an-object .adoption-items .object .image-wrap .adopted:after {
  content: '';
  border: 10px solid #992E2E;
  padding: 1rem;
}

.adopt-an-object .adoption-items .object .image-wrap .adopted:before {
  border-right: none;
}

.adopt-an-object .adoption-items .object .image-wrap .adopted:after {
  border-left: none;
}

@media (min-width: 769px) and (max-width: 992px) {
  .adopt-an-object .adoption-items .object .image-wrap .adopted {
    font-size: 40px;
  }
  .adopt-an-object .adoption-items .object .image-wrap .adopted:before, .adopt-an-object .adoption-items .object .image-wrap .adopted:after {
    border-width: 7px;
  }
}

@media (max-width: 600px) {
  .adopt-an-object .adoption-items .object .image-wrap .adopted {
    font-size: 40px;
  }
  .adopt-an-object .adoption-items .object .image-wrap .adopted:before, .adopt-an-object .adoption-items .object .image-wrap .adopted:after {
    border-width: 7px;
  }
}

.adopt-an-object .adoption-items .object h4 {
  font-weight: 600;
  margin-bottom: 10px;
  border-bottom: 2px solid;
  padding-bottom: 5px;
}

.adopt-an-object .adoption-items .object .cost {
  display: block;
  text-align: center;
  padding: 15px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin: 20px 0;
  font-weight: 600;
}

.adopt-an-object .adoption-items .object .patrons .patron:after {
  content: ', ';
}

.adopt-an-object .adoption-items .object .patrons .patron:last-child:after {
  content: '';
}
