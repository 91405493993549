.social-section {
    background-image: url("/tcm/images/social-section-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 4rem 0;

    h1 {
        color: $black;
    }

    &:before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-color: rgba(255, 255, 255, 0.9);
    }


    .btn {
        &.btn-twitter {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: $twitter;
            color: $white;
            text-align: center;
            text-transform: none;
            font-size: 18px;
            border-color: $twitter;
            margin: 0 0 1rem 0;

            img {
                margin-bottom: 0 !important;
                margin-right: .5rem;
            }
        }
    }


    .twitter-timeline {
        .timeline-Widget {
            .timeline-Footer {
                display: none;
            }
        }
    }

    .facebook-section, .instagram-section {
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        color: $white;
        z-index: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 480px) {
            background-image: none;
        }

        &:before {
            position: absolute;
            content: '';
            display: block;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            margin: 0 auto;
        }

        .content {
            display: flex;
            position: relative;
            z-index: 1;
            width: 100%;
            justify-content: center;
            color: $white;

            img {
                margin-right: .5rem;
                @media screen and (max-width: 480px) {
                    margin-bottom: 0 !important;
                }
            }

            @media screen and (max-width: 480px) {
                padding: .5rem 0;
            }
        }
    }

  .facebook-section {
    background-image: url("/tcm/images/facebook_bg_image.png");
    @media screen and (max-width: 480px) {
      background-color: $facebook;
    }

    &:before {
      background-color: rgba(57, 87, 155, 0.7);
    }
  }

  .instagram-section {
    background-image: url("/tcm/images/facebook_bg_image.png");
    background-image: url("/tcm/images/instagram_bg_image.png");
    @media screen and (max-width: 480px) {
      background-color: $instagram;
    }

    &:before {
      background-color: rgba($instagram, 0.7);
    }
  }
}


