.fact-slider-section{
  padding: 3rem 0rem;
  h3{
    text-transform: uppercase;
    letter-spacing: .2rem;
    color: $black;
  }
  hr{
    width: 280px;
    border-color: $black;
  }
  .fact-slider-wrapper{
    position: relative;
    .fact-slider{
      .slide{
        .slide-content{
          max-width: 550px;
          margin: 0 auto;
          color: $black;
        }
      }
    }
    .prev-button,
    .next-button{
      display: block;
      position: absolute;
      top: 50%;
    }
    .prev-button{
      left: 0;
    }
    .next-button{
      right: 0;
    }
  }
}