.key-figures{
  .figure-info{
    p{
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  .dashboard{
    .dashboard-content{
      background-color: $white;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .page-intro-with-image{
    .title{
      width: 100%;
      max-width: 100%;
      text-align: left;
    }
  }

  .key-figure{
    padding-bottom: 2rem;
    &:not(:last-child){
      border-bottom: 1px solid $lightGrey;
      margin-bottom: 2rem;
    }
    .key-significance{
      margin-top: 2rem;
    }
  }
}
