.whats-on-single{
  .image-with-content-box{
    @media screen and (max-width: 480px){
      margin-bottom: 0;
    }
  }
  .btn{
    &.btn-transparent{
      border-color: $grey;
      color: $grey !important;
    }
    i{
      margin-right: 1rem;
    }
  }
}